<script>
import AliceLogoMark from '../../components/shared/AliceLogoMark';
import PasswordVisibilityToggle from "../../components/Login/PasswordVisibilityToggle.vue";
import PopUpCardModal from '../../components/shared/PopUpCardModal.vue';
import { setPasswordRestore } from '../../services/session';
import router from '../../router';

export default {
    name: 'SetNewPassword',
    props: {},
    components: {
        AliceLogoMark,
        PasswordVisibilityToggle,
        PopUpCardModal,
    },
    mounted() {
        window.scrollTo(0, 0);
        if (!this.$route.query.email || !this.$route.query.token) {
            if (this.$route.meta.from === "/my-account") {
                router.push("/");
            } else {
                router.push("/login");
            }
            return;
        }
        this.email = this.$route.query.email ? this.$route.query.email.toLowerCase() : 'user@gmail.com';
    },
    computed: {
        isNewPasswordNotEmpty() {
            return this.newPassword.length > 0;
        },
        isActiveSaveBtn() {
            return this.newPassword.length > 0 && this.confirmPassword.length > 0
        },
        isValidPassword() {
            // Validate that the passwords are the same
            const passwordsMatch = this.newPassword === this.confirmPassword;

            // Validate that the passwords have at least 8 characters
            const hasMinLength = this.newPassword.length >= 8 && this.confirmPassword.length >= 8;

            // Show specific error messages if the conditions are not met
            if (!passwordsMatch) {
                return this.errorMessages.passwordsNotMatch;
            } else if (!hasMinLength) {
                return this.errorMessages.minLength;
            } else {
                // If all conditions are true, the password is valid.
                return null;
            }

        },
    },
    data() {
        return {
            newPassword: '',
            confirmPassword: '',
            showPassword: false,
            showPasswordError: false,
            showConfirmPasswordError: false,
            saveBtnClicked: false,
            loading: false,
            email: '',
            textMsgError: '',
            errorMessages: {
                passwordsNotMatch: 'Passwords do not match.',
                minLength: 'The password must have at least 8 characters.',
            },
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        handleEyeClick() {
            if (this.isNewPasswordNotEmpty) {
                this.showPassword = !this.showPassword;
            }
        },
        async onSaveButtonClick() {
            if (!this.isActiveSaveBtn) return;
            this.saveBtnClicked = true;
            const newPasswordNotEmpty = this.isNewPasswordNotEmpty;
            const confirmPasswordNotEmpty = this.confirmPassword.length > 0;
            const isValid = newPasswordNotEmpty && confirmPasswordNotEmpty && !this.isValidPassword;

            // Activate the display of error messages when an error occurs.
            this.showPasswordError = !newPasswordNotEmpty || !isValid;
            this.showConfirmPasswordError = !confirmPasswordNotEmpty || !isValid;

            if (isValid) {
                // Logic that will be executed when passwords are valid.
                this.loading = true;
                const res_data = await setPasswordRestore({
                    token: this.$route.query.token,
                    password: this.newPassword,
                });
                if (res_data.error === false) {
                    this.textMsgError = 'Password changed successfully.';
                    this.$refs.modalError.showModal();
                    router.push("/login");
                } else {
                    if (res_data.message) {
                        this.textMsgError = res_data.message;
                        this.$refs.modalError.showModal();
                    } else {
                        this.textMsgError = 'An error has occurred.';
                        this.$refs.modalError.showModal();
                    }
                }
            } else {
                console.log('Error:', 'Invalid passwords');
            }
        },
    }
}
</script>

<template>
    <div class="verify-email-container pt-5">
        <section class="section-container">

            <AliceLogoMark />
            <div class="verify-email-text-container min-width">
                <template>
                    <h1 class="title pb-4">Set a new password</h1>
                    <div class="d-flex pb-5 example flex-column">
                        <p class="subtitle pt-5 text-left">New password for {{ this.email }}</p>
                        <form @submit.prevent="findPassword">
                            <div class="d-flex flex-column">
                                <p class="subtitle pt-5 text-left">New password</p>

                                <input :type="showPassword ? 'text' : 'password'"
                                    :class="['login-email padding-input', !showPassword ? 'space-letter-pass' : '']"
                                    placeholder="" v-model="newPassword" />

                                <p v-if="!saveBtnClicked || !isValidPassword" class="create-password-text">Create a password
                                    with at least 8
                                    characters.</p>
                                <p v-else class="create-password-text text-error">
                                    {{ isValidPassword }}
                                </p>
                                <!-- HIDE/PASSWORD (EYE)  -->
                                <div class="eye-container" v-on:click="handleEyeClick">
                                    <PasswordVisibilityToggle :showPassword="showPassword" v-if="isNewPasswordNotEmpty" />
                                </div>



                                <p class="subtitle text-left">Confirm password</p>

                                <input :type="showPassword ? 'text' : 'password'" class="login-email padding-input"
                                    placeholder="" v-model="confirmPassword" autocomplete="off"
                                    :class="['login-email padding-input', !showPassword ? 'space-letter-pass' : '']" />


                                <p v-if="isValidPassword && saveBtnClicked" class="create-password-text text-error">
                                    {{ isValidPassword }}
                                </p>
                            </div>


                            <div class="actions-btn-container">
                                <div class="action-btn">
                                    <span @click="goBack" class="cancel-btn">Cancel</span>
                                </div>
                                <div class="action-btn">
                                    <span v-on:click="onSaveButtonClick"
                                        :class="['save-btn', isActiveSaveBtn ? '' : 'no-active']">Save</span>
                                </div>
                            </div>
                        </form>
                    </div>


                </template>

            </div>

            <PopUpCardModal ref="modalError" titleCard="Oops" :textMsg="textMsgError" :isOk="true" />


            <footer class="footer-bobble-graph">
                <img class="w-100" src="../../assets/Loginbackground.svg" alt="bobble-graph" width="100%" height="100%">
            </footer>
        </section>
    </div>
</template>


<style scoped>
@import '../../assets/styles/views/password/ForgottenPassword.css';

.instructions {
    color: var(--color-purple);
    font-family: Helvetica;
    font-weight: 300;
}

.title {
    font-size: 28px;
}

.subtitle {
    font-size: 19px;
}

.padding-input {
    padding: 0.8rem 1.6rem !important;
}

.create-password-text {
    font-size: 13px;
    font-family: Helvetica;
    color: #9b9b9b;
    text-align: left;
    margin-top: 3px;
}

.min-width {
    width: 385px;
}

.actions-btn-container {
    display: flex;
    justify-content: flex-end;
    gap: 6rem;
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.action-btn span {
    font-size: 16px;
    cursor: pointer;
}

.cancel-btn {
    color: black;
}

.save-btn {
    color: var(--color-purple);
}

.no-active {
    opacity: 0.5;
    cursor: unset !important;
}

.space-letter-pass {
    letter-spacing: 4px;
}

.eye-container {
    cursor: pointer;
    position: relative;
    padding: 1rem;
    width: 40px;
    height: 40px;
    top: -7rem;
    left: 85%;
    /*top: -10rem;*/
}

.text-error {
    color: red !important;
}
</style>
